import * as React from 'react'
import {
  ErrorModal,
  Input,
  LoginButton,
  OnLoading,
  MaterialIcon,
} from 'components'
import { useSignUp } from '@oneblink/apps-react'
import { Form, SubmitButton } from './Login'
import { Typography } from '@mui/material'

const formsAppId = window.formsHostnameConfiguration?.formsAppId

function SignUp() {
  const [username, setUsername] = React.useState('')
  const [firstName, setFirstName] = React.useState<string | undefined>(
    undefined,
  )
  const [lastName, setLastName] = React.useState<string | undefined>(undefined)
  const {
    signUpWithUserDetails,
    clearSignUpError,
    isSigningUp,
    signUpError,
    signUpComplete,
    usernameValidation,
  } = useSignUp({
    formsAppId: formsAppId || -1,
    username,
    firstName,
    lastName,
  })

  if (signUpComplete) {
    return (
      <div className="ob-login__box box ob-login__container">
        <MaterialIcon className="has-text-success icon-x-large ob-submission-success__icon">
          check_circle
        </MaterialIcon>
        <Typography variant="h5">Success!</Typography>
        <Typography variant="body1" align="center">
          An email has been sent to your address with your temporary password.
        </Typography>
        <div style={{ paddingTop: '8px' }}>
          <LoginButton />
        </div>
      </div>
    )
  }

  if (isSigningUp) {
    return <OnLoading />
  }

  return (
    <>
      <div className="ob-login__box box">
        <Form
          onSubmit={signUpWithUserDetails}
          label="Sign up with your details."
        >
          <div>
            <Input
              name="username"
              type="email"
              label="Email Address"
              value={username}
              onChange={setUsername}
              isInvalid={username ? usernameValidation.isInvalid : null}
              icon="email"
              data-cypress="username-input"
              autoComplete="username"
            />

            <Input
              name="firstName"
              type="firstName"
              label="First Name (optional)"
              value={firstName || ''}
              onChange={setFirstName}
              isInvalid={null}
              icon="person"
              data-cypress="first-name-input"
            />

            <Input
              name="lastName"
              type="lastName"
              label="Last Name (optional)"
              value={lastName || ''}
              onChange={setLastName}
              isInvalid={null}
              icon="person"
              data-cypress="first-name-input"
            />

            <SubmitButton
              isLoading={isSigningUp}
              isInvalid={usernameValidation.isInvalid}
            >
              Sign Up
            </SubmitButton>
          </div>

          {signUpError && (
            <ErrorModal error={signUpError} onClose={clearSignUpError} />
          )}
        </Form>
      </div>
      <div className="container ob-login__container ob-login__box">
        <Typography variant="caption" color="GrayText" align="center">
          Already have an account?
        </Typography>
        <LoginButton className="ob-login__login-screen-button" isSecondary />
      </div>
    </>
  )
}

export default SignUp
